<div class="message">
    <div class="icon">
        <img *ngIf="message.sender !== Sender.You" src="assets/logo.png" />
        <div *ngIf="message.sender === Sender.You" class="user-icon">Y</div>
    </div>
    <div class="content" [class.add-space-after]="sendToServer">
        <div class="sender">{{message.sender}}</div>
        <app-file *ngFor="let file of message.files" [fileName]="file.fileName"></app-file>
        <ng-container *ngFor="let part of message.content; let partInd = index;">
            <span class="image" *ngIf="part.imageName">
                <img [src]="getImageSource(part.imageName)" />
            </span>
            <span class="text" *ngIf="part.text && (message.sender === Sender.You || !withTyping)" [class.not-you]="message.sender !== Sender.You">
                <span [innerHTML]="part.text"></span>
            </span>
            <ngx-typed-js *ngIf="part.text && message.sender !== Sender.You && withTyping" [strings]="[part.text]" [shuffle]="false"
                [loop]="false" [showCursor]="false" [typeSpeed]="20" [startDelay]="startDelay"
                (completed)="onCompleteTyping.emit()">
                <span class="text not-you">
                    <span class="typing"></span>
                    <svg *ngIf="!typingIsStarted && partInd == message.content.length - 1" class="circle__spinner" viewBox="0 0 15 15">
                        <circle class="path" cx="7.5" cy="7.5" r="6" fill="none" stroke-width="2"></circle>
                    </svg>
                    <img *ngIf="typingIsStarted && !typingIsComplete && partInd == message.content.length - 1" class="typing-img" src="../../../assets/icons/blackTyping.gif" />
                </span>
            </ngx-typed-js>
            <app-code-popup *ngIf="part.code" [codeInnerHtml]="part.code" [inline]="true" [codeType]="part.codeType || ''"></app-code-popup>
        </ng-container>
        <span *ngIf="message.hasCode" class="analysis-btn" [ngClass]="{'show-btns': typingIsComplete}"
            mat-raised-button #tooltip="matTooltip" matTooltip="View analysis" matTooltipPosition="above"
            matTooltipClass="linked" (click)="openCodePopupClicked.emit(message)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 15" fill="none"
                class="-mt-0.5 ml-0.5 inline-block text-link-base hover:text-link-hover" width="19" height="15">
                <path
                    d="M4.42 0.75H2.8625H2.75C1.64543 0.75 0.75 1.64543 0.75 2.75V11.65C0.75 12.7546 1.64543 13.65 2.75 13.65H2.8625C2.8625 13.65 2.8625 13.65 2.8625 13.65C2.8625 13.65 4.00751 13.65 4.42 13.65M13.98 13.65H15.5375H15.65C16.7546 13.65 17.65 12.7546 17.65 11.65V2.75C17.65 1.64543 16.7546 0.75 15.65 0.75H15.5375H13.98"
                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                </path>
                <path
                    d="M5.55283 4.21963C5.25993 3.92674 4.78506 3.92674 4.49217 4.21963C4.19927 4.51252 4.19927 4.9874 4.49217 5.28029L6.36184 7.14996L4.49217 9.01963C4.19927 9.31252 4.19927 9.7874 4.49217 10.0803C4.78506 10.3732 5.25993 10.3732 5.55283 10.0803L7.95283 7.68029C8.24572 7.3874 8.24572 6.91252 7.95283 6.61963L5.55283 4.21963Z"
                    fill="currentColor" stroke="currentColor" stroke-width="0.2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
                <path
                    d="M9.77753 8.75003C9.3357 8.75003 8.97753 9.10821 8.97753 9.55003C8.97753 9.99186 9.3357 10.35 9.77753 10.35H13.2775C13.7194 10.35 14.0775 9.99186 14.0775 9.55003C14.0775 9.10821 13.7194 8.75003 13.2775 8.75003H9.77753Z"
                    fill="currentColor" stroke="currentColor" stroke-width="0.1"></path>
            </svg>
        </span>
        <svg *ngIf="!message.content.length" class="circle__spinner" [class.wait-to-server]="sendToServer" viewBox="0 0 15 15">
            <circle class="path2" cx="7.5" cy="7.5" r="6" fill="none" stroke="lightgray" stroke-width="2"></circle>
            <circle class="path" cx="7.5" cy="7.5" r="6" fill="none" stroke-width="2"></circle>
        </svg>
        <div class="buttons" [ngClass]="{'show-btns': typingIsComplete}" *ngIf="!sendToServer">
            <div class="edit" *ngIf="message.sender === Sender.You">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="icon-md">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M13.2929 4.29291C15.0641 2.52167 17.9359 2.52167 19.7071 4.2929C21.4783 6.06414 21.4783 8.93588 19.7071 10.7071L18.7073 11.7069L11.1603 19.2539C10.7182 19.696 10.1489 19.989 9.53219 20.0918L4.1644 20.9864C3.84584 21.0395 3.52125 20.9355 3.29289 20.7071C3.06453 20.4788 2.96051 20.1542 3.0136 19.8356L3.90824 14.4678C4.01103 13.8511 4.30396 13.2818 4.7461 12.8397L13.2929 4.29291ZM13 7.41422L6.16031 14.2539C6.01293 14.4013 5.91529 14.591 5.88102 14.7966L5.21655 18.7835L9.20339 18.119C9.40898 18.0847 9.59872 17.9871 9.7461 17.8397L16.5858 11L13 7.41422ZM18 9.5858L14.4142 6.00001L14.7071 5.70712C15.6973 4.71693 17.3027 4.71693 18.2929 5.70712C19.2831 6.69731 19.2831 8.30272 18.2929 9.29291L18 9.5858Z"
                        fill="currentColor">
                    </path>
                </svg>
            </div>
            <ng-container *ngIf="message.sender !== Sender.You">
                <div class="paste">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="icon-md">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M12 4C10.8954 4 10 4.89543 10 6H14C14 4.89543 13.1046 4 12 4ZM8.53513 4C9.22675 2.8044 10.5194 2 12 2C13.4806 2 14.7733 2.8044 15.4649 4H17C18.6569 4 20 5.34315 20 7V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V7C4 5.34315 5.34315 4 7 4H8.53513ZM8 6H7C6.44772 6 6 6.44772 6 7V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V7C18 6.44772 17.5523 6 17 6H16C16 7.10457 15.1046 8 14 8H10C8.89543 8 8 7.10457 8 6Z"
                            fill="currentColor">
                        </path>
                    </svg>
                </div>
                <div class="like">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="icon-md">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M12.1318 2.50389C12.3321 2.15338 12.7235 1.95768 13.124 2.00775L13.5778 2.06447C16.0449 2.37286 17.636 4.83353 16.9048 7.20993L16.354 8.99999H17.0722C19.7097 8.99999 21.6253 11.5079 20.9313 14.0525L19.5677 19.0525C19.0931 20.7927 17.5124 22 15.7086 22H6C4.34315 22 3 20.6568 3 19V12C3 10.3431 4.34315 8.99999 6 8.99999H8C8.25952 8.99999 8.49914 8.86094 8.6279 8.63561L12.1318 2.50389ZM10 20H15.7086C16.6105 20 17.4008 19.3964 17.6381 18.5262L19.0018 13.5262C19.3488 12.2539 18.391 11 17.0722 11H15C14.6827 11 14.3841 10.8494 14.1956 10.5941C14.0071 10.3388 13.9509 10.0092 14.0442 9.70591L14.9932 6.62175C15.3384 5.49984 14.6484 4.34036 13.5319 4.08468L10.3644 9.62789C10.0522 10.1742 9.56691 10.5859 9 10.8098V19C9 19.5523 9.44772 20 10 20ZM7 11V19C7 19.3506 7.06015 19.6872 7.17071 20H6C5.44772 20 5 19.5523 5 19V12C5 11.4477 5.44772 11 6 11H7Z"
                            fill="currentColor">
                        </path>
                    </svg>
                </div>
                <div class="dislike">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="icon-md">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M11.8727 21.4961C11.6725 21.8466 11.2811 22.0423 10.8805 21.9922L10.4267 21.9355C7.95958 21.6271 6.36855 19.1665 7.09975 16.7901L7.65054 15H6.93226C4.29476 15 2.37923 12.4921 3.0732 9.94753L4.43684 4.94753C4.91145 3.20728 6.49209 2 8.29589 2H18.0045C19.6614 2 21.0045 3.34315 21.0045 5V12C21.0045 13.6569 19.6614 15 18.0045 15H16.0045C15.745 15 15.5054 15.1391 15.3766 15.3644L11.8727 21.4961ZM14.0045 4H8.29589C7.39399 4 6.60367 4.60364 6.36637 5.47376L5.00273 10.4738C4.65574 11.746 5.61351 13 6.93226 13H9.00451C9.32185 13 9.62036 13.1506 9.8089 13.4059C9.99743 13.6612 10.0536 13.9908 9.96028 14.2941L9.01131 17.3782C8.6661 18.5002 9.35608 19.6596 10.4726 19.9153L13.6401 14.3721C13.9523 13.8258 14.4376 13.4141 15.0045 13.1902V5C15.0045 4.44772 14.5568 4 14.0045 4ZM17.0045 13V5C17.0045 4.64937 16.9444 4.31278 16.8338 4H18.0045C18.5568 4 19.0045 4.44772 19.0045 5V12C19.0045 12.5523 18.5568 13 18.0045 13H17.0045Z"
                            fill="currentColor">
                        </path>
                    </svg>
                </div>
                <div class="refresh">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="icon-md">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M4.5 2.5C5.05228 2.5 5.5 2.94772 5.5 3.5V5.07196C7.19872 3.47759 9.48483 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C7.1307 21.5 3.11828 17.8375 2.565 13.1164C2.50071 12.5679 2.89327 12.0711 3.4418 12.0068C3.99033 11.9425 4.48712 12.3351 4.5514 12.8836C4.98798 16.6089 8.15708 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C9.7796 4.5 7.7836 5.46469 6.40954 7H9C9.55228 7 10 7.44772 10 8C10 8.55228 9.55228 9 9 9H4.5C3.96064 9 3.52101 8.57299 3.50073 8.03859C3.49983 8.01771 3.49958 7.99677 3.5 7.9758V3.5C3.5 2.94772 3.94771 2.5 4.5 2.5Z"
                            fill="currentColor">
                        </path>
                    </svg>
                </div>
            </ng-container>
        </div>
    </div>
</div>