import { Component, Input, ViewChild } from '@angular/core';
import { MathJaxService } from 'src/app/services/math-jax/math-jax.service';

@Component({
  selector: 'app-math-jax-paragraph',
  standalone: true,
  templateUrl: './math-jax-paragraph.component.html',
  styleUrls: ['./math-jax-paragraph.component.scss']
})
export class MathJaxParagraphComponent {
    @ViewChild('mathParagraph') paragraphElement: any;
    @Input({ required: true }) mathString!: string;

    constructor(private mathJaxService: MathJaxService) {}

    ngOnInit() {
        this.mathJaxService.getMathJaxLoadedPromise().then(() => {
            // Insert the input string
            this.paragraphElement.nativeElement.innerHTML = this.mathString;
            // Render the Latex
            this.mathJaxService.render(this.paragraphElement.nativeElement);
        });
    }
}
