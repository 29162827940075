import { Component, EventEmitter, Input, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxTypedJsComponent } from 'ngx-typed-js';
import Message from 'src/app/entities/Message';
import { Sender } from 'src/app/entities/Sender';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
    @ViewChildren(NgxTypedJsComponent) typed?: QueryList<NgxTypedJsComponent>;

    @Input() message!: Message;
    @Input() typingIsComplete: boolean = true;
    @Input() withTyping: boolean = true;
    @Output() openCodePopupClicked = new EventEmitter<Message>();
    @Output() onCompleteTyping = new EventEmitter();
    sendToServer = environment.sendToServer;
    startDelay = 3500;
    typingIsStarted: boolean = false;

    constructor(private route: ActivatedRoute) {
        this.route.queryParams.subscribe(params => {
			if (params['sendToServer']) {
				this.sendToServer = params['sendToServer'].toString().toLowerCase() == 'true';
			}
        });
    }

    ngOnInit() {
        if (this.sendToServer) {
            this.startDelay = 0;
        }
        setTimeout(()=> { this.typingIsStarted = true; }, this.startDelay);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.sendToServer && changes['typingIsComplete'] && this.typingIsComplete) {
            // stop typing
            this.typed?.last?.stop();
        }
    }

    get Sender() {
      return Sender;
    }

    getImageSource(imageName: string) {
      if (this.sendToServer) {
        return `${environment.host}/images/${imageName}`;
      } else {
        return `../../assets/imagesForAnswers/${imageName}`;
      }
    }
}
