import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-code-popup',
  templateUrl: './code-popup.component.html',
  styleUrls: ['./code-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CodePopupComponent {
  @ViewChild('code') private code?: ElementRef;
  @Output() close = new EventEmitter();
  @Input() messageId!: string;
  @Input() codeInnerHtml!: string;
  @Input() codeResultInnerHtml!: string;
  @Input() inline = false;
  @Input() codeType = 'python';
  copied = false;

  constructor(private clipboard: Clipboard) {}

  copyCodeClicked() {
    this.copied = true;
    this.clipboard.copy(this.code?.nativeElement.innerText);
    setTimeout(() => {
      this.copied = false;
    }, 2000);
  }
}
