import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() messagesLength: number = 0;
  @Output() clearChatEvent = new EventEmitter<boolean>();
  showMenu: boolean = false;

  clearChat(){
   this.clearChatEvent.emit(true);
  }
}
