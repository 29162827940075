import { Sender } from "./Sender";

export default class Message {
    id: string;
    sender!: Sender;
    content: MessageContent[];
    files: FileData[] = [];
    hasCode: boolean = false;
    code?: string;
    codeResult?: string;

    constructor(id: string, sender: Sender | "Chat" | "You", content: MessageContent[], code?: string, codeResult?: string, files?: FileData[]) {
        this.id = id;
        this.sender = ["Chat", "You"].includes(sender) ? Sender[sender as "Chat" | "You"] : sender as Sender;
        this.content = content.map(part => new MessageContent(part));
        if (code) {
            this.hasCode = true;
            this.code = code.includes('<code') ? code : this.buildCodeHtml(code);
            this.codeResult = codeResult;
        }
        this.files = files? files: [];
    }

    buildCodeHtml(code: string) {
        let html = `<code class='!whitespace-pre hljs language-python'>`;
        code = code.replace(/\r\n/g, '\n');
        let rowsArray = code.split('\n');
        for (let row of rowsArray) {
            let hasComment = row.indexOf('#') >= 0;
            let notCommentPart = hasComment ? row.substring(0, row.indexOf('#')) : row;
            let commentPart = hasComment ? row.substring(row.indexOf('#')) : '';
            row = this.colorsNonCommentParts(notCommentPart);
            if (hasComment) {
                row += `<span class='hljs-comment'>${commentPart}</span>`;
            }
            html += row + '\n';
        }
        html += `</code>`;
        return html;
    }

    colorsNonCommentParts(text: string) {
        let newText = '';
        // strings
        let endPrevString = 0;
        let { startString, stringOpenChar } = this.findStartStringIndexAndChar(text, 0);
        while (startString > -1) {
            // before string
            let notString = text.substring(endPrevString, startString);
            newText += this.wrapKeywordAndNumbers(notString);
            // string
            const endString = text.indexOf(stringOpenChar, startString + 1);
            const str = text.substring(startString, endString + 1);
            newText += `<span class='hljs-string'>${str}</span>`;
            // next string
            const result = this.findStartStringIndexAndChar(text, endString + 1);
            startString = result.startString;
            stringOpenChar = result.stringOpenChar;
            endPrevString = endString + 1;
        }
        // after string
        if (endPrevString < text.length) {
            let notString = text.substring(endPrevString);
            newText += this.wrapKeywordAndNumbers(notString);
        }
        return newText;
    }

    findStartStringIndexAndChar(text: string, indexToStartSearch: number = 0) {
        let startStringType1 = text.indexOf(`'`, indexToStartSearch);
        let startStringType2 = text.indexOf(`"`, indexToStartSearch);
        let startString = startStringType2;
        let stringOpenChar = `"`;
        if (startStringType1 > -1 && (startStringType2 == -1 || startStringType1 < startStringType2)) {
            startString = startStringType1;
            stringOpenChar = `'`;
        }
        return { startString, stringOpenChar };
    }

    wrapKeywordAndNumbers(text: string) {
        // keywords
        const keywords = ['import', 'as', 'for', 'in', 'if', 'else', 'elif', 'with', 'from', 'True', 'False'];
        keywords.forEach(word => {
            const regex = new RegExp(`\\b${word}\\b`, 'g');
            text = text.replace(regex, `<span class='hljs-keyword'>${word}</span>`);
        });
        // numbers
        const matches = text.match(/\b\d+\b/g);
        if (matches) {
            let prevNumInd = 0;
            matches.forEach(number => {
                let numInd = text.indexOf(number, prevNumInd);
                text = `${text.substring(0, numInd)}<span class='hljs-number'>${number}</span>${text.substring(numInd + number.length)}`;
                prevNumInd = numInd + number.length + `<span class='hljs-number'></span>`.length;
            });
        }
        return text;
    }
}

export class MessageContent {
    text?: string;
    imageName?: string;
    code?: string;
    codeType?: string;

    constructor(data: any) {
        if (data.text) this.text = data.text;
        if (data.imageName) this.imageName = data.imageName;
    }
}

export class FileData {
    fileName!: string;
    fileServerName?: string;
}