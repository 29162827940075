import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { ChatComponent } from './components/chat/chat.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileComponent } from './components/file/file.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CodePopupComponent } from './components/code-popup/code-popup.component';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { HttpClientModule } from '@angular/common/http';
import { MessageComponent } from './components/message/message.component';
import { MathJaxParagraphComponent } from './components/math-jax-paragraph/math-jax-paragraph.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ChatComponent,
    FileComponent,
    CodePopupComponent,
    MessageComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MatTooltipModule,
    FormsModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    NgxTypedJsModule,
    MathJaxParagraphComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
