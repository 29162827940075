import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
	route = 'file';

    constructor(private apiService: ApiService) { }

    async uploadFile(file: File) {
		const formData = new FormData();
        formData.append("file", file, file.name);
        const response = await firstValueFrom(this.apiService.postRequest(`${this.route}`, formData)) as any;
        return response?.fileName || '';
	}

    async uploadFiles(files: File[]) {
        const formData = new FormData();
        files.forEach((file) => { formData.append('files', file); });
        const response = await firstValueFrom(this.apiService.postRequest(`${this.route}/multi`, formData)) as any;
        return response?.filesNames || [];
	}
}
