<app-header [messagesLength]="messages.length + oldMessages.length" (clearChatEvent)="clearChat()"></app-header>
<div class="wrap-chat">
    <div class="chat-window" #chat_window>
        <div class="center">
            <div class="empty-state" *ngIf="!messages.length && !oldMessages.length">
                <div class="wrap-logo">
                    <img class="logo" src="assets/logo.png" />
                </div>
                <div class="title">MDClone Research Sandbox</div>
                <div class="description">Data analytics powered by {{modelLLM}}</div>

            </div>
            <div class="chat" *ngIf="oldMessages.length || messages.length">
                <app-message *ngFor="let message of oldMessages"
                    [message]="message"
                    [typingIsComplete]="typingIsComplete"
                    [withTyping]="false"
                    (openCodePopupClicked)="openCodePopup($event)"
                    (onCompleteTyping)="onCompletedTyping()"
                ></app-message>
                <app-message *ngFor="let message of messages"
                    [message]="message"
                    [typingIsComplete]="typingIsComplete"
                    [withTyping]="true"
                    (openCodePopupClicked)="openCodePopup($event)"
                    (onCompleteTyping)="onCompletedTyping()"
                ></app-message>
                <app-math-jax-paragraph *ngIf="typingIsComplete && hasEquation" mathString=""></app-math-jax-paragraph>
            </div>
        </div>
    </div>
    <div class="input-part">
        <div class="center">
            <div class="textarea-part">
                <div class="wrap-textarea">
                    <div *ngIf="files.length" class="files">
                        <div class="wrap-file" *ngFor="let file of files; let fileInd = index;">
                            <app-file [fileName]="file.fileName" [showLoader]="true"></app-file>
                            <div class="remove-file-btn" (click)="removeFile(fileInd)">
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24"
                                    stroke-linecap="round" stroke-linejoin="round" class="icon-sm" height="1em" width="1em"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <textarea placeholder="Message MDClone Research Sandbox..." [(ngModel)]="inputText"
                        (keydown.enter)="sendMessage()" (keydown)="AltPlusWForInsertQuestion($event)"></textarea>
                    <div class="upload-file-btn" (click)="file_upload.click()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9 7C9 4.23858 11.2386 2 14 2C16.7614 2 19 4.23858 19 7V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V7C17 5.34315 15.6569 4 14 4C12.3431 4 11 5.34315 11 7V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V7Z"
                                fill="currentColor"></path>
                        </svg>
                    </div>
                    <button class="send-btn" *ngIf="typingIsComplete" [class.not-active]="inputText === '' && !files.length" mat-raised-button
                        #tooltip="matTooltip" matTooltip="Send message" matTooltipPosition="above"
                        matTooltipClass="above" (click)="sendMessage()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-white dark:text-black">
                            <path d="M7 11L12 6L17 11M12 18V7" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </button>
                    <button class="stop-btn" *ngIf="!sendToServer && !typingIsComplete" (click)="stopTyping()">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                            class="h-2 w-2 text-gizmo-gray-950 dark:text-gray-200" height="16" width="16">
                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"
                                stroke-width="0"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <input type="file" (change)="uploadFile($event)" #file_upload hidden multiple>
    </div>
</div>
<div *ngIf="showCodePopup" class="popup-background" (click)="showCodePopup = false">
    <app-code-popup (close)="showCodePopup = false" (click)="clickOnPopup($event)" [messageId]="messageForCodePopup.id"
        [codeInnerHtml]="messageForCodePopup.code || ''"
        [codeResultInnerHtml]="messageForCodePopup.codeResult || ''"></app-code-popup>
</div>