import { Component, ElementRef, ViewChild } from '@angular/core';
import Message, { MessageContent } from 'src/app/entities/Message';
import { Sender } from 'src/app/entities/Sender';
import questionsJson from '../../../assets/questions_answers.json';
import Q_A, { Answer } from 'src/app/entities/Q_A';
import { MessageService } from 'src/app/services/message/message.service';
import { FileService } from 'src/app/services/file/file.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  @ViewChild('chat_window') private chatWindow?: ElementRef;

  messages: Message[] = [];
  oldMessages: Message[] = [];
  questions_and_answers: Q_A[] = [];
  inputText = '';
  defaultMessage =
    'It seems like there might have been a typo in your message. Could you please clarify your request or question?';
  questions: string[] = [];
  showCodePopup = false;
  messageForCodePopup: Message = new Message('', Sender.Chat, []);
  typingIsComplete: boolean = true;
  sendToServer = environment.sendToServer;
  threadId?: string | undefined;
  modelLLM: string = environment.modelLLM;
	files: { fileName: string, fileServerName?: string, file?: File }[] = [];
	hasEquation = false;
	lastMessageContent: MessageContent[] = [];
	index = 0;

  constructor(private route: ActivatedRoute, private messageService: MessageService, private fileService: FileService, private router: Router) { 
		this.route.queryParams.subscribe(params => {
			if (params['sendToServer']) {
				this.sendToServer = params['sendToServer'].toString().toLowerCase() == 'true';
			}
			this.threadId = params['threadId'];
			if (this.threadId && !this.oldMessages.length && !this.messages.length) {
				this.getMessageByThreadId();
			}
			if (params['files']) {
				const newFiles = Array.isArray(params['files']) ? params['files'] : [ params['files'] ];
				for (let file of newFiles) {
					if (this.files.length == 10) {
						setTimeout(() => { 
							alert("Limited to 10 files");
						}, 10);
						break;
					}
					const fileName = JSON.parse(file).fileName;
					const fileServerName = JSON.parse(file).fileServerName;
					this.files.push({
						fileName,
						fileServerName
					});
					this.scrollChat();
				}
				this.removeQueryParams(['files']);
			}
		});
    this.modelLLM = environment.modelLLM;
  }

  get Sender() {
    return Sender;
  }

  ngOnInit() {
    this.initQuestions();
  }

  removeQueryParams(params: string[]) {
    const queryParams: Params = {};
    params.forEach(param => queryParams[param] = null);
    this.router.navigate([], {
        queryParams: queryParams,
        queryParamsHandling: 'merge'
    });
  }

  initQuestions() {
    var dict_questions = JSON.parse(JSON.stringify(questionsJson));
    Object.keys(dict_questions).forEach((key) => {
      let current_question = dict_questions[key].question;
      this.questions_and_answers.push(
        new Q_A(
          new Answer(
            dict_questions[key].answer.isContainsCode,
            dict_questions[key].answer.code,
            dict_questions[key].answer.codeResult,
            dict_questions[key].answer.text,
            dict_questions[key].answer.imageName
          ),
          current_question
        )
      );
      this.questions.push(current_question);
    });
  }

  async sendMessage() {
    let currentInput = JSON.parse(JSON.stringify(this.inputText));
    if (this.inputText === '' && !this.files.length) return;
    this.typingIsComplete = false;
    const userMessage = new Message(
      this.messages.length.toString(),
      Sender.You,
      [{ text: this.inputText }],
      undefined,
      undefined,
      this.files.map(file => { return { fileName: file.fileName }})
    );
    this.addMessageToView(userMessage);
		// copy files array to clean the files in the UI
		const filesArr = this.files.slice();
    setTimeout(() => {
      this.inputText = '';
      this.files = [];
    }, 5);

    if (!this.sendToServer) {
      setTimeout(() => {
        let answer = this.questions_and_answers.find(
          (q_a) => q_a.question.toLowerCase() == currentInput.toLowerCase().trim()
        )?.answer;
        const answerWithImage = answer?.imageName != "" && answer?.imageName != undefined;
        const timeoutMs = answerWithImage ? 1500 : 0;
        setTimeout(() => {
          this.typingIsComplete = answerWithImage;
          const content = [];
          if (answer?.imageName) {
            content.push({ imageName: answer.imageName });
          }
          if (answer?.text) {
            content.push({ text: answer.text });
          }
					if (!answer) {
						content.push({ text: this.defaultMessage });
					}
          this.addMessageToView(
            new Message(
              this.messages.length.toString(),
              Sender.Chat,
              content,
              answer?.code,
              answer?.codeResult
            )
          );
          this.scrolDownWhenTyping();
        }, timeoutMs);
      }, 300);
    } else {
      this.messages.push(new Message('', Sender.Chat, [])); // Add message to show the gif of typing
			try {
				if (filesArr.length) {
					// get index of files to upload
					const indexes = filesArr
						.map((file, index) => {
							if (!file.fileServerName) {
								return index;
							}
							return undefined;
						})
						.filter(fileIndex => fileIndex != undefined) as number[];
					if (indexes.length) {
						// get files to upload
						const fileToUpload = filesArr.filter(file => !file.fileServerName && file.file).map(file => file.file) as File[];
						// upload files
						const filesServerNames = await this.fileService.uploadFiles(fileToUpload);
						// linked file server name to file
						indexes.forEach((fileIndex, index) => filesArr[fileIndex].fileServerName = filesServerNames[index]);
					}
				}
				// send message (with file list)
				const response = await this.messageService.sendMessage(userMessage, filesArr.map(file => { return { fileName: file.fileName, fileServerName: file.fileServerName }}), this.threadId);
				this.messages.pop();
				if (!this.threadId) {
					this.router.navigate([], {
						queryParams: { threadId: response.threadId },
						queryParamsHandling: 'merge'
					});
				}
				this.threadId = response.threadId;
				if (response.answer) {
					response.answer.forEach((answer: Message) => this.addMessageToView(answer));
					this.scrolDownWhenTyping();
				}
			} catch (error) {
				console.error('An error occurred', error);
				this.typingIsComplete = true;
				if(!this.messages[this.messages.length - 1].content.length) {
					this.messages.pop();
				}
				alert('An error occurred. Please refresh the page');
				return;
			}
    }
  }

  async getMessageByThreadId() {
    if (this.threadId) {
      const messages = await this.messageService.getMessages(this.threadId);
			messages.forEach(message => this.addDesign(message));
      this.oldMessages = messages.slice();
      this.scrollChat();
    }
  }

	addBoldDesign(message: Message) {
		// replace ** with <strong></strong>
    for (let i = 0; i < message.content.length; i++) {
      if (message.content[i].text != undefined) {
				const text = message.content[i].text as string;
				message.content[i].text = text.replace(/\*{2}(.*?)\*{2}/g, '<strong>$1</strong>');
      }
    }
	}

  getHTMLForTabularFormat(response: string) {
    let newStr = response.substring(0, response.indexOf("|")) + "<table>";
    let rows = response.substring(response.indexOf("|")).split("\n");
    newStr += "<thead>"
    let row = rows[0];
    let cols = row.split("|");
    newStr += "<tr>";
    for (let j = 1; j < cols.length - 1; j++) {
      let col = cols[j];
      let cellValue = col.trim();
      if (!cellValue.includes("---")) {
        newStr += "<th>";
        newStr += cellValue;
        newStr += "</th>";
      }
    }
    newStr += "</tr>";
    newStr += "</thead>"
    newStr += "<tbody>"
    for (var i = 2; i < rows.length; i++) {

      let row = rows[i];
      let cols = row.split("|");
      newStr += "<tr>";
      for (let j = 1; j < cols.length - 1; j++) {
        let col = cols[j];
        let cellValue = col.trim();
        if (!cellValue.includes("---")) {
          newStr += "<td>";
          newStr += cellValue;
          newStr += "</td>";
        }
      }
      newStr += "</tr>";

    }
    newStr += "</tbody>"
    newStr += "</table>";
    if (response.length > response.lastIndexOf("|") + 1) {
      newStr += response.substring(response.lastIndexOf("|") + 1)
    }
    return newStr.replaceAll("\n\n", "\n");
  }

  addTableDesign(message: Message) {
    for (let i = 0; i < message.content.length; i++) {
      if (message.content[i].text) {
        if (message.content[i].text != undefined && message.content[i].text?.toString().includes("|")) {
          message.content[i].text = this.getHTMLForTabularFormat(message.content[i].text?.toString() as string);
        }
        message.content[i].text = "<div class='markdown'>" + message.content[i].text + "</div>";
      }
    }
	}

	separateTheCode(message: Message) {
		const content: MessageContent[] = [];
		for (let part of message.content) {
			if (part.text && part.text.length && part.text.includes('```')) {
				let codeEndIndex = 0;
				let codeStartIndex = part.text.indexOf('```');
				while (codeStartIndex > -1) {
					let beforeCode = part.text.substring(codeEndIndex, codeStartIndex);
					content.push({ text: beforeCode });
					// extract code type
					let codeTypeEndIndex = part.text.indexOf('\n', codeStartIndex);
					let codeType = part.text.substring(codeStartIndex + 3, codeTypeEndIndex);
					// extract code
					codeEndIndex = part.text.indexOf('```', codeStartIndex + 3);
					let code = part.text.substring(codeTypeEndIndex + 1, codeEndIndex);
					content.push({ code: message.buildCodeHtml(code), codeType });
					codeEndIndex = codeEndIndex + 3;
					codeStartIndex = part.text.indexOf('```', codeEndIndex);
				}
				let afterCode = part.text.substring(codeEndIndex);
				content.push({ text: afterCode });
			} else {
				content.push(part);
			}
		}
		message.content = content;
	}

	addDesign(message: Message) {
		if (!this.hasEquation && message.content.some(part => part.text && part.text.includes("\\\("))) {
			this.hasEquation = true;
		}
		this.separateTheCode(message);
		this.addBoldDesign(message);
		this.addTableDesign(message);
	}

	addMessageToView(message: Message) {
		this.addDesign(message);
		if (this.sendToServer && message.sender == Sender.Chat) {
			// Add each part of the content separately
			this.lastMessageContent = message.content.slice(1); // save the next parts
			message.content.splice(1); // remove the next parts from the message
			this.index = 0;
			this.messages.push(message);
			if (message.content[0].imageName || message.content[0].code) {
				// If the part is an image or a code, immediately call the function again because there is no "typing" time
				this.addAnotherPartOfLastMessageToView();
			}
		} else {
			this.messages.push(message);
		}
		this.scrollChat();
  }

	addAnotherPartOfLastMessageToView() {
		if (this.index < this.lastMessageContent.length) {
			this.typingIsComplete = false;
			this.messages[this.messages.length - 1].content.push(this.lastMessageContent[this.index]); // Add another part
			this.index++;
			this.scrollChat();
			if (this.lastMessageContent[(this.index-1)].imageName || this.lastMessageContent[(this.index-1)].code) {
				// If the part is an image or a code, immediately call the function again because there is no "typing" time
				this.addAnotherPartOfLastMessageToView();
			}
		} else {
			this.lastMessageContent = [];
			this.index = 0;
			this.typingIsComplete = true;
		}
	}

  clearChat() {
    this.messages = [];
    this.oldMessages = [];
    this.inputText = '';
    this.files = [];
    this.threadId = undefined;
    this.removeQueryParams(['threadId']);
  }

  scrollChat() {
    // scroll chat window to the bottom
    setTimeout(() => {
      if (this.chatWindow) {
        this.chatWindow.nativeElement.scroll({
          top: this.chatWindow.nativeElement.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 200);
  }

  uploadFile(event: any) {
		for (let file of event.target.files) {
			if (this.files.length == 10) {
				alert("Limited to 10 files");
				break;
			}
			this.files.push({
				fileName: file.name,
				file
			});
			this.scrollChat();
		}
		event.target.value = null;	
  }

  removeFile(fileIndex: number) {
		this.files.splice(fileIndex, 1);
  }

  openCodePopup(message: Message) {
    this.messageForCodePopup = message;
    this.showCodePopup = true;
  }

  clickOnPopup(event: Event) {
    event.stopPropagation();
  }

  onCompletedTyping() {
    this.scrollChat();
    this.typingIsComplete = true;
		this.addAnotherPartOfLastMessageToView();
  }

  stopTyping() {
    this.typingIsComplete = true;
  }

  scrolDownWhenTyping() {
    const interval = setInterval(() => {
      if (this.typingIsComplete) {
        clearInterval(interval);
      } else {
        this.scrollChat();
      }
    }, 100);
  }

  AltPlusWForInsertQuestion(event: any) {
    if (event.altKey && event.key === 'w' || event.altKey && event.key === '\'') {
      let id = (this.oldMessages.length + this.messages.length) / 2;
      if (this.questions.length > id) {
        let next_question = this.questions[id];
        let next_word = "";
        if (this.inputText.includes(" ")) {
          next_word = next_question.substring(this.inputText.lastIndexOf(" ") + 1).split(" ")[0];
        } else {
          if (next_question?.includes(" ")) {
            next_word = next_question.split(" ")[0];
          }
        }
        if (next_word != "") {
          this.inputText += next_word + " ";
          let textAreaEl = document.getElementsByTagName("textarea")[0];
          textAreaEl.scrollTop = textAreaEl.scrollHeight;
        }
      }
    }
  }
}
