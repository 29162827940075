import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import Message, { FileData } from 'src/app/entities/Message';
import { ApiService } from 'src/app/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
    route = 'message';

    constructor(private apiService: ApiService) { }

    async getMessages(threadId: string) {
        const queryParams = threadId ? `?threadId=${threadId}` : '';
        const messages = await firstValueFrom(this.apiService.getRequest(`${this.route}${queryParams}`)) as any[];
        return messages.map(message => {
            const {id, sender, content, code, codeResult, files} = message;
            return new Message(id, sender, content, code, codeResult, files);
        });
    }

    async sendMessage(message: Message, filesData?: FileData[], threadId?: string) {
        const body = {
            threadId: threadId,
            text: message.content[0].text,
            files: filesData
        };
        const response = await firstValueFrom(this.apiService.postRequest(`${this.route}`, body)) as any;
        if (response?.answer?.length) {
            const newMessages = response.answer.map((answer: any) => {
                const {id, sender, content, code, codeResult, files} = answer;
                return new Message(id, sender, content, code, codeResult, files);
            });
            return {
                threadId: response.threadId,
                answer: newMessages
            }; 
        }
        return {
            threadId: response.threadId
        };
    }
}
