import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class FileComponent {
  @Input() fileName!: string;
  @Input() showLoader = false;
  fileType: string = 'file';
  fileTypeName: string = 'File';

  extensionsDictionary: Map< string, { fileTypeName: string, type: string } > = new Map([
    [ '.c', { fileTypeName: 'C', type: 'code' } ],
    [ '.cpp', { fileTypeName: 'C++', type: 'code' } ],
    [ '.csv', { fileTypeName: 'Spreadsheet', type: 'spreadsheet' } ],
    [ '.docx', { fileTypeName: 'Document', type: 'document' } ],
    [ '.html', { fileTypeName: 'File', type: 'file' } ],
    [ '.java', { fileTypeName: 'Java', type: 'code' } ],
    [ '.json', { fileTypeName: 'File', type: 'file' } ],
    [ '.md', { fileTypeName: 'File', type: 'file' } ],
    [ '.pdf', { fileTypeName: 'PDF', type: 'document' } ],
    [ '.php', { fileTypeName: 'PHP', type: 'code' } ],
    [ '.pptx', { fileTypeName: 'Presentation', type: 'presentation' } ],
    [ '.py', { fileTypeName: 'Python', type: 'code' } ],
    [ '.rb', { fileTypeName: 'Ruby', type: 'code' } ],
    [ '.tex', { fileTypeName: 'Latex', type: 'code' } ],
    [ '.txt', { fileTypeName: 'Document', type: 'document' } ],
    [ '.css', { fileTypeName: 'File', type: 'file' } ],
    [ '.jpeg', { fileTypeName: 'JPEG', type: 'image' } ],
    [ '.jpg', { fileTypeName: 'JPG', type: 'image' } ],
    [ '.js', { fileTypeName: 'Javascript', type: 'code' } ],
    [ '.gif', { fileTypeName: 'GIF', type: 'image' } ],
    [ '.png', { fileTypeName: 'PNG', type: 'image' } ],
    [ '.tar', { fileTypeName: 'File', type: 'file' } ],
    [ '.ts', { fileTypeName: 'Typescript', type: 'code' } ],
    [ '.xlsx', { fileTypeName: 'Spreadsheet', type: 'spreadsheet' } ],
    [ '.xml', { fileTypeName: 'File', type: 'file' } ],
    [ '.zip', { fileTypeName: 'Zip Archive', type: 'zip' } ]
  ]);

  ngOnInit() {
    const extension = this.fileName.substring(this.fileName.lastIndexOf('.'));
    const typeDetails = this.extensionsDictionary.get(extension);
    if (typeDetails) {
        this.fileTypeName = typeDetails.fileTypeName;
        this.fileType = typeDetails.type;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showLoader'] && this.showLoader) {
      setTimeout(() => { this.showLoader = false; }, 2500);
    }
  }
}
