
export default class Q_A {
    question: string;
    answer: Answer;

    constructor(_answer: Answer, _question: string) {
        this.answer = _answer;
        this.question = _question;
    }
}

export class Answer {
    isContainsCode: boolean;
    code: string;
    codeResult: string;
    text: string;
    imageName: string;

    constructor(_isContainsCode: string, _code: string, _codeResult: string, _text: string, _imageName: string) {
        this.isContainsCode = _isContainsCode.toLowerCase() == "true";
        this.code = _code;
        this.codeResult = _codeResult;
        this.text = _text;
        this.imageName = _imageName;
    }
}
