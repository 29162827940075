import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private host = environment.host;

  constructor(private http: HttpClient) { }

  getRequest(path: string) {
    return this.http.get(`${this.host}/api/${path}`);
  }

  postRequest(path: string, body: any) {
    return this.http.post(`${this.host}/api/${path}`, body);
  }
}
