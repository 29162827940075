<div class="file" *ngIf="fileName">
    <div class="file-icon">
        <svg *ngIf="fileType == 'code'" viewBox="0 0 36 36" fill="none">
            <rect width="36" height="36" rx="6" fill="#FF6E3C"></rect>
            <path *ngIf="!showLoader" d="M21.333 23L26.333 18L21.333 13" stroke="white" stroke-width="1.66667"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path *ngIf="!showLoader" d="M14.667 13L9.66699 18L14.667 23" stroke="white" stroke-width="1.66667"
                stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        <svg *ngIf="fileType == 'spreadsheet'" viewBox="0 0 36 36" fill="none">
            <rect width="36" height="36" rx="6" fill="#10A37F"></rect>
            <path *ngIf="!showLoader"
                d="M15.5 10.5H12.1667C11.2462 10.5 10.5 11.2462 10.5 12.1667V13.5V18M15.5 10.5H23.8333C24.7538 10.5 25.5 11.2462 25.5 12.1667V13.5V18M15.5 10.5V25.5M15.5 25.5H18H23.8333C24.7538 25.5 25.5 24.7538 25.5 23.8333V18M15.5 25.5H12.1667C11.2462 25.5 10.5 24.7538 10.5 23.8333V18M10.5 18H25.5"
                stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round">
            </path>
        </svg>
        <svg *ngIf="fileType == 'document'" viewBox="0 0 36 36" fill="none">
            <rect width="36" height="36" rx="6" fill="#FF5588"></rect>
            <path *ngIf="!showLoader"
                d="M19.6663 9.66663H12.9997C12.5576 9.66663 12.1337 9.84222 11.8212 10.1548C11.5086 10.4673 11.333 10.8913 11.333 11.3333V24.6666C11.333 25.1087 11.5086 25.5326 11.8212 25.8451C12.1337 26.1577 12.5576 26.3333 12.9997 26.3333H22.9997C23.4417 26.3333 23.8656 26.1577 24.1782 25.8451C24.4907 25.5326 24.6663 25.1087 24.6663 24.6666V14.6666L19.6663 9.66663Z"
                stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
            <path *ngIf="!showLoader" d="M19.667 9.66663V14.6666H24.667" stroke="white" stroke-width="1.66667"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path *ngIf="!showLoader" d="M21.3337 18.8334H14.667" stroke="white" stroke-width="1.66667"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path *ngIf="!showLoader" d="M21.3337 22.1666H14.667" stroke="white" stroke-width="1.66667"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path *ngIf="!showLoader" d="M16.3337 15.5H15.5003H14.667" stroke="white" stroke-width="1.66667"
                stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        <svg *ngIf="fileType == 'file'" viewBox="0 0 36 36" fill="none">
            <rect width="36" height="36" rx="6" fill="#0000FF"></rect>
            <path *ngIf="!showLoader"
                d="M18.833 9.66663H12.9997C12.5576 9.66663 12.1337 9.84222 11.8212 10.1548C11.5086 10.4673 11.333 10.8913 11.333 11.3333V24.6666C11.333 25.1087 11.5086 25.5326 11.8212 25.8451C12.1337 26.1577 12.5576 26.3333 12.9997 26.3333H22.9997C23.4417 26.3333 23.8656 26.1577 24.1782 25.8451C24.4907 25.5326 24.6663 25.1087 24.6663 24.6666V15.5L18.833 9.66663Z"
                stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
            <path *ngIf="!showLoader" d="M18.833 9.66663V15.5H24.6663" stroke="white" stroke-width="1.66667"
                stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        <svg *ngIf="fileType == 'presentation'" viewBox="0 0 36 36" fill="none">
            <rect width="36" height="36" rx="6" fill="#F8CA27"></rect>
            <path *ngIf="!showLoader"
                d="M10.5 14V13.5V12.1667C10.5 11.2462 11.2462 10.5 12.1667 10.5H15.5H23.8333C24.7538 10.5 25.5 11.2462 25.5 12.1667V13.5V14M10.5 14V23.8333C10.5 24.7538 11.2462 25.5 12.1667 25.5H15.5H18H23.8333C24.7538 25.5 25.5 24.7538 25.5 23.8333V14M10.5 14H25.5M14 21.5L16.5 19L19 20.5L22 17.5"
                stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        <svg *ngIf="fileType == 'image'" viewBox="0 0 36 36" fill="none">
            <rect width="36" height="36" rx="6" fill="#FB0505"></rect>
            <path *ngIf="!showLoader" d="M6.502 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" transform="translate(10,10)" />
            <path *ngIf="!showLoader"
                d="M14 14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zM4 1a1 1 0 0 0-1 1v10l2.224-2.224a.5.5 0 0 1 .61-.075L8 11l2.157-3.02a.5.5 0 0 1 .76-.063L13 10V4.5h-2A1.5 1.5 0 0 1 9.5 3V1z"
                stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" transform="translate(10,10)" />
        </svg>
        <svg *ngIf="fileType == 'zip'" viewBox="0 0 36 36" fill="none">
            <rect width="36" height="36" rx="6" fill="#B161FD"></rect>
            <path *ngIf="!showLoader"
                d="M26.3337 23.8333C26.3337 24.2754 26.1581 24.6993 25.8455 25.0118C25.5329 25.3244 25.109 25.5 24.667 25.5H11.3337C10.8916 25.5 10.4677 25.3244 10.1551 25.0118C9.84259 24.6993 9.66699 24.2754 9.66699 23.8333V12.1667C9.66699 11.7246 9.84259 11.3007 10.1551 10.9882C10.4677 10.6756 10.8916 10.5 11.3337 10.5H15.5003L17.167 13H24.667C25.109 13 25.5329 13.1756 25.8455 13.4882C26.1581 13.8007 26.3337 14.2246 26.3337 14.6667V23.8333Z"
                stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        <svg *ngIf="showLoader" class="circle__spinner" viewBox="0 0 36 36">
            <circle class="path2" cx="18" cy="18" r="8" fill="none" stroke="gray" stroke-width="1"></circle>
            <circle class="path" cx="18" cy="18" r="8" fill="none" stroke="gray" stroke-width="1"></circle>
        </svg>
    </div>
    <div class="details">
        <div class="file-name">{{fileName}}</div>
        <div class="file-type">{{fileTypeName}}</div>
    </div>
</div>