

<div *ngIf="showMenu" data-radix-popper-content-wrapper="" dir="ltr"
    style="z-index: 1 !important; background: white; position: fixed; left: 0px; top: 0px; transform: translate3d(60px, 50px, 0px); min-width: max-content; z-index: auto; --radix-popper-anchor-width: 193.0104217529297px; --radix-popper-anchor-height: 44px; --radix-popper-available-width: 858px; --radix-popper-available-height: 580px; --radix-popper-transform-origin: 0% 0px;">
    <div data-side="bottom" data-align="start" role="menu" aria-orientation="vertical" data-state="open"
        data-radix-menu-content="" dir="ltr" id="radix-:ri:" aria-labelledby="radix-:rh:"
        class="mt-2 min-w-[100px] max-w-xs rounded-lg border border-gray-100 bg-token-surface-primary shadow-lg dark:border-gray-700"
        tabindex="-1" data-orientation="vertical"
        style="outline: none; --radix-dropdown-menu-content-transform-origin: var(--radix-popper-transform-origin); --radix-dropdown-menu-content-available-width: var(--radix-popper-available-width); --radix-dropdown-menu-content-available-height: var(--radix-popper-available-height); --radix-dropdown-menu-trigger-width: var(--radix-popper-anchor-width); --radix-dropdown-menu-trigger-height: var(--radix-popper-anchor-height); pointer-events: auto;">
        <div role="menuitem"
            class=" font-small flex gap-2 m-1.5 rounded p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-black/5 dark:hover:bg-white/5 radix-disabled:pointer-events-none radix-disabled:opacity-50 group"
            tabindex="-1" data-orientation="vertical" data-radix-collection-item=""><svg width="24" height="24"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M16.7929 2.79289C18.0118 1.57394 19.9882 1.57394 21.2071 2.79289C22.4261 4.01184 22.4261 5.98815 21.2071 7.20711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7348 8.10536 11.4804 8.29289 11.2929L16.7929 2.79289ZM19.7929 4.20711C19.355 3.7692 18.645 3.7692 18.2071 4.2071L10 12.4142V14H11.5858L19.7929 5.79289C20.2308 5.35499 20.2308 4.64501 19.7929 4.20711ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34314 4.34315 3 6 3H10C10.5523 3 11 3.44771 11 4C11 4.55228 10.5523 5 10 5H6Z"
                    fill="currentColor"></path>
            </svg>New chat</div>
        <div role="menuitem"
            class="font-small flex gap-2 m-1.5 rounded p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-black/5 dark:hover:bg-white/5 radix-disabled:pointer-events-none radix-disabled:opacity-50 group"
            tabindex="-1" data-orientation="vertical" data-radix-collection-item=""><svg width="24" height="24"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md">
                <path
                    d="M13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V12Z"
                    fill="currentColor"></path>
                <path
                    d="M12 9.5C12.6904 9.5 13.25 8.94036 13.25 8.25C13.25 7.55964 12.6904 7 12 7C11.3096 7 10.75 7.55964 10.75 8.25C10.75 8.94036 11.3096 9.5 12 9.5Z"
                    fill="currentColor"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
                    fill="currentColor"></path>
            </svg>About</div>
        <div role="menuitem"
            class="font-small flex gap-2 m-1.5 rounded p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-black/5 dark:hover:bg-white/5 radix-disabled:pointer-events-none radix-disabled:opacity-50 group"
            tabindex="-1" data-orientation="vertical" data-radix-collection-item=""><svg width="24" height="24"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md">
                <path
                    d="M15 15V17.5585C15 18.4193 14.4491 19.1836 13.6325 19.4558L13.1726 19.6091C12.454 19.8487 11.6616 19.6616 11.126 19.126L4.87403 12.874C4.33837 12.3384 4.15132 11.546 4.39088 10.8274L4.54415 10.3675C4.81638 9.55086 5.58066 9 6.44152 9H9M12 6.2L13.6277 3.92116C14.3461 2.91549 15.7955 2.79552 16.6694 3.66942L20.3306 7.33058C21.2045 8.20448 21.0845 9.65392 20.0788 10.3723L18 11.8571"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M8 16L3 21" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
                <path d="M4 4L20 20" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>Hide from sidebar</div>
        <div role="menuitem"
            class="flex gap-2 m-1.5 rounded p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-black/5 dark:hover:bg-white/5 radix-disabled:pointer-events-none radix-disabled:opacity-50 group"
            tabindex="-1" data-orientation="vertical" data-radix-collection-item=""><svg width="24" height="24"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.2929 5.70711C16.4743 3.88849 13.5257 3.88849 11.7071 5.70711L10.7071 6.70711C10.3166 7.09763 9.68341 7.09763 9.29289 6.70711C8.90236 6.31658 8.90236 5.68342 9.29289 5.2929L10.2929 4.29289C12.8926 1.69323 17.1074 1.69323 19.7071 4.2929C22.3068 6.89256 22.3068 11.1074 19.7071 13.7071L18.7071 14.7071C18.3166 15.0976 17.6834 15.0976 17.2929 14.7071C16.9024 14.3166 16.9024 13.6834 17.2929 13.2929L18.2929 12.2929C20.1115 10.4743 20.1115 7.52572 18.2929 5.70711ZM15.7071 8.2929C16.0976 8.68342 16.0976 9.31659 15.7071 9.70711L9.7071 15.7071C9.31658 16.0976 8.68341 16.0976 8.29289 15.7071C7.90236 15.3166 7.90236 14.6834 8.29289 14.2929L14.2929 8.2929C14.6834 7.90237 15.3166 7.90237 15.7071 8.2929ZM6.7071 9.2929C7.09763 9.68342 7.09763 10.3166 6.7071 10.7071L5.7071 11.7071C3.88849 13.5257 3.88849 16.4743 5.7071 18.2929C7.52572 20.1115 10.4743 20.1115 12.2929 18.2929L13.2929 17.2929C13.6834 16.9024 14.3166 16.9024 14.7071 17.2929C15.0976 17.6834 15.0976 18.3166 14.7071 18.7071L13.7071 19.7071C11.1074 22.3068 6.89255 22.3068 4.29289 19.7071C1.69322 17.1074 1.69322 12.8926 4.29289 10.2929L5.29289 9.2929C5.68341 8.90237 6.31658 8.90237 6.7071 9.2929Z"
                    fill="currentColor"></path>
            </svg>Copy link</div>
        <div role="menuitem"
            class="font-small flex gap-2 m-1.5 rounded p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-black/5 dark:hover:bg-white/5 radix-disabled:pointer-events-none radix-disabled:opacity-50 group"
            tabindex="-1" data-orientation="vertical" data-radix-collection-item=""><svg width="24" height="24"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6 4.64048V13.4946C6.97966 13.1602 7.88099 13.0195 8.73583 13.0151C9.9815 13.0088 11.0694 13.2939 12.0551 13.5954C12.2535 13.6561 12.448 13.7172 12.6399 13.7776C14.3554 14.3169 15.8581 14.7893 18 14.2515V5.61807C18 5.6177 18 5.61836 18 5.61807C17.9973 5.61407 17.9883 5.60143 17.9674 5.58771C17.9132 5.55219 17.8375 5.53958 17.7738 5.55802C15.2418 6.29023 13.3072 5.56144 11.6733 4.94591C11.5513 4.89998 11.4311 4.85467 11.3124 4.81066C9.6336 4.18815 8.14486 3.74466 6 4.64048ZM18 14.2793C18 14.2796 18 14.2795 18 14.2793V14.2793ZM6 15.637C7.06717 15.1777 7.95744 15.0191 8.74594 15.0151C9.68064 15.0104 10.5334 15.2214 11.4701 15.5079C11.657 15.5651 11.8472 15.6255 12.0411 15.6871C13.7733 16.2375 15.806 16.8833 18.556 16.1737C19.4513 15.9427 20 15.1264 20 14.2793V5.61807C20 4.07012 18.4318 3.28577 17.2182 3.63675C15.3407 4.1797 13.9832 3.67281 12.3112 3.04848C12.2112 3.01115 12.1101 2.9734 12.0077 2.93543C10.1745 2.25564 8.06074 1.59138 5.17004 2.81991C4.41981 3.13875 4 3.87462 4 4.61803V21C4 21.5523 4.44772 22 5 22C5.55228 22 6 21.5523 6 21V15.637Z"
                    fill="currentColor"></path>
            </svg>Report</div>
    </div>
</div>